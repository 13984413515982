html, body, div {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: #333;
    width: 100%;
    height: 100%;
    line-height: 1;
}

h1 {
    font-size: 22px;
    padding: 0px;
    margin: 0px 0px 15px 0px;
    color: #831625;
}

p {
    line-height: 22px;
    margin-bottom: 18px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

strong {
    font-weight: bold;
}

.body-normal {
    background-color: #fff;
    box-sizing: content-box;
}

.body-acceptance {
    background-color: #f3f7d4;
}

.hoeken_3_boven {
    border-radius: 3px 3px 0px 0px;
    position: relative;
}

.hoeken_5 {
    border-radius: 5px;
    position: relative;
}

.zoekselect {
    width: 257px;
    line-height: 20px;
    height: 30px;
    margin: 3px 0px 15px 0px;
}

.dummy {
    overflow: visible;
}

/* WRAPPER */
#wrapper {
    margin: 0px auto 20px;
    position: relative;
    width: 960px;
    padding: 0px 20px;
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;
}

/* HEADER */
#header {
    background-color: #9f9f9f;
    position: relative;
    overflow: hidden;
}

#header .overlay {
    height: 132px;
    width: 960px;
    top: 0px;
    right: 0px;
    position: absolute;
    overflow: hidden;
    z-index: 8;
}

#header .label {
    position: absolute;
    padding: 0px 30px 0px 15px;
    left: 0px;
    top: 25px;
    line-height: 25px;
    color: #fff;
    font-weight: bold;
    background-color: #831625;
    overflow: hidden;
    z-index: 9;
}

#header .titelbalk {
    position: absolute;
    left: 0px;
    bottom: 41px;
    padding: 13px 40px;
    color: #fff;
    font-size: 28px;
    background-color: #1a1a1a9f;
    width: 880px;
    z-index: 9;
}

#header .afbeeldingen {
    height: 132px;
    overflow: hidden;
}

#header .afbeeldingen img {
    height: 132px;
}

#header .navigatie {
    background-color: #255c9f;
    overflow: hidden;
}

#header .navigatie ul {
    list-style: none;
    margin: 0px;
    padding: 10px 10px 0px 10px;
}

#header .navigatie ul li {
    list-style: none;
    margin: 0px;
    padding: 0px;
    float: left;
}

#header .navigatie ul li a {
    display: block;
    color: #fff;
    font-weight: bold;
    line-height: 31px;
    overflow: hidden;
    text-decoration: none;
    padding: 0px 8px;
    margin: 0px 2px;
}

#header .navigatie ul li.active a {
    background-color: #0096d2;
    text-decoration: none;
    cursor: default;
}

#header .navigatie ul li a:hover {
    background-color: #0096d2;
    text-decoration: none;
}

.content {
    padding: 0px;
    background-color: #fff;
    overflow: hidden;
}

.content_main {
    width: 629px;
    float: left;
    position: relative;
    padding: 30px 23px 115px 23px;
}

.content_right {
    float: right;
    position: relative;
    width: 285px;
    padding: 37px 0px 115px 0px;
}

.content_footer {
    background-color: #255c9f;
    padding-top: 10px;
    overflow: hidden;
}

/* FOOTER */
#footer {
    padding: 17px 10px;
    overflow: hidden;
}

#footer .copyright {
    float: left;
    font-size: 11px;
    width: 450px;
    color: #fff;
}

#footer .copyright a {
    color: #fff;
    text-decoration: none;
}

#footer .copyright a:hover {
    color: #831625;
    text-decoration: none;
}

#footer .menu {
    float: right;
    width: 450px;
}

#footer .menu ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

#footer .menu ul li {
    list-style: none;
    margin: 0px;
    padding: 0px;
    float: left;
}

#footer .menu ul li a {
    display: block;
    color: #fff;
    overflow: hidden;
    text-decoration: none;
    padding: 0px 10px;
}

#footer .menu ul li a:hover {
    color: #831625;
    text-decoration: none;
}

/* NIEUWS WIDGET */
#nieuws_widget {
    overflow: hidden;
}

#nieuws_widget .tabkoppen {
    overflow: hidden;
}

#nieuws_widget .tabkoppen .tab {
    float: left;
    font-size: 12px;
    margin-right: 10px;
    color: #fff;
    padding: 0px 10px;
    line-height: 30px;
    background-color: #255c9f;
    overflow: hidden;
}

#nieuws_widget .tabkoppen .tab.actief {
    background-color: #0096d2;
    color: #fff;
    cursor: default;
}

#nieuws_widget .tabbladen {
    padding: 5px 0px 40px 0px;
    background-color: #efefef;
}

#nieuws_widget .tabblad.actief {
    display: block;
}

#nieuws_widget .tabblad ul {
    margin: 0px;
    padding-left: 25px;
    list-style: disclosure-closed;
    color: #8c8c8c;
}

#nieuws_widget .tabblad ul li {
    margin: 0px;
    padding: 0px;
    border-top: 1px solid #d7d7d7;
}

#nieuws_widget .tabblad ul li a:hover {
    text-decoration: underline;
    color: #0096d2;
}

#nieuws_widget .tabblad ul li a {
    display: block;
    padding: 8px 20px 8px 5px;
    text-decoration: none;
    color: #000;
}

#nieuws_widget .tabblad ul li.eerste {
    border: 0px;
}

/* ZOEKEN WIDGET */
#zoeken_widget {
    overflow: hidden;
}

#zoeken_widget .tabkoppen {
    overflow: hidden;
}

#zoeken_widget .tabkoppen .tab {
    float: left;
    font-size: 12px;
    margin-right: 10px;
    color: #fff;
    padding: 0px 10px;
    line-height: 30px;
    background-color: #595959;
    overflow: hidden;
    cursor: pointer;
}

#zoeken_widget .tabkoppen .tab.actief {
    background-color: #0096d2;
    color: #fff;
    cursor: default;
}

#zoeken_widget .tabbladen {
    padding: 5px 0px 5px 0px;
    background-color: #999;
}

#zoeken_widget .tabblad {
    display: block;
}

#zoeken_widget .advanced_search .inputtable {
    width: 247px;
    border: 0px;
    background-color: #fff;
    color: #333;
    display: block;
    font-size: 12px;
    height: 25px;
    line-height: 25px;
    margin-bottom: 15px;
    padding: 0px 5px;
    text-align: left;
}

#zoeken_widget .advanced_search label {
    display: block;
    font-size: 12px;
    color: #fff;
    margin-bottom: 5px;
}

#zoeken_widget .advanced_search .button {
    padding: 6px 12px;
    background-color: #831625;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: 700;
    cursor: pointer;
}

#zoeken_widget .advanced_search .button:hover {
    text-decoration: none;
    background-color: #dc0000;
    color: #fff;
}

/* DOCUMENTS */
.document-hoofd {
    background-color: #dedede;
    margin: 10px 0px 0px 0px;
    padding: 0px 5px;
    font-weight: bold;
    color: #2d2d2d;
    line-height: 31px;
    position: relative;
    overflow: hidden;
}

.document-hoofd a {
    text-decoration: none;
    font-size: 12px;
    color: #000;
}

.document-hoofd a:hover {
    text-decoration: underline;
    color: #0096d2;
}

.document-hoofd .date {
    color: #ec2439;
    font-size: 11px;
    text-align: center;
}

.document-hoofd table ul {
    margin: 0px;
    padding-left: 25px;
    list-style: disclosure-open;
    color: #8c8c8c;
}

.document-hoofd .document-marker ul {
    margin: 0px;
    padding-left: 25px;
    list-style: disclosure-closed;
    color: #8c8c8c;
}

.document-content {
    margin-top: 10px;
    padding: 5px;
    background-color: #f6f6f6;
    position: relative;
    color: #000;
    overflow: hidden;
    overflow-wrap: anywhere;
}

.document-content table tr {
    border-bottom: 1px solid #cecece;
}

.document-content .zoekoverzicht ul {
    margin: 0px;
    padding-left: 13px;
    list-style: square;
    line-height: 21px;
}

.document-content table a {
    color: #0096d2;
    font-weight: bold;
    text-decoration: none;
}

.document-content table a:hover {
    color: #0096d2;
    text-decoration: underline;
}

.document-content .zoekoverzicht {
    padding: 8px 10px;
}

.document-content .icoon ul {
    margin: 0px;
    padding-left: 25px;
    list-style: square;
    color: #8c8c8c;
}

/* NAV */
#nav {
    display: block;
}

#nav .pager {
    padding-left: 0;
    margin: 20px 0px;
    text-align: center;
    list-style: none;
}

#nav .pager li {
    display: inline;
}

#nav .pager li a {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    text-decoration: none;
    color: #337ab7;
}

#nav .pager li a:hover {
    text-decoration: none;
    background-color: #eee;
}

#nav .pager .paging-disabled {
    pointer-events: none;
}

#nav .pager .disabled a {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
}

.page_home {
    height: 261px;
    font-size: 13px;
    line-height: 17px;
}

/*
body {
    background-color: #fff;
    box-sizing: content-box;
}

div {
    display: block;
}

p {
    line-height: 25px;
    font-size: 16px;
}

div.page {
    margin: 20px auto 20px auto;
    width: 1100px;
    padding: 0px 20px;
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;
}

div.logo {
    margin-top: 20px;
    margin-bottom: 20px;
}

div.header {
    margin-top: 20px;
    margin-bottom: 20px;
}

#index-extra-text {
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 0;
}

div.intro {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 0;
}

div.content {
    margin-top: 20px;
}

div.tabs {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

div.tabs::after {
    content: '';
    display: block;
    clear: both;
}

div.tab-element {
    height: 100%;
    width: 100%;
    background-position: 15px 15px;
    width: 155px !important;
    height: 80px !important;
    background-repeat: no-repeat;
}

h1.header {
    padding: .3em .5em;
    border-radius: 0 12px 0 0;
    background-color: #831625;
    margin-left: 0;
    margin-right: 0;
    color: #fff;
    font-size: 1.625em;
    font-weight: bold;
    font-style: normal;
    line-height: 47px;
}

p.title {
    margin-top: 12px;
    margin-bottom: 12px;
}

a.md-title {
    color: #255c9f;
    font-weight: bold;
    text-decoration: none;
}

a.md-title:hover {
    cursor: pointer;
}

.grid {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.grid::after {
    content: '';
    display: block;
    clear: both;
}

.task-element {
    float: left;
    width: 24.95%;
    height: 85px;
    display: block;
}

.task-outer {
    height: 100%;
    padding: 1px;
}

.task-inner {
    height: 100%;
    background-color: #255c9f;
}

.task-inner-active {
    height: 100%;
    background-color: #0096d2;
}

.task-inner:hover {
    background-color: #0096d2;
    cursor: pointer;
}

.task-title {
    color: #fff;
    display: inline-block;
    text-align: left;
    margin-top: 13px;
    margin-left: 50px;
    line-height: 1.2em;
    font-size: 18px;
    padding-right: 20px;
    width: 100%;
}

.button-ovs {
    background-color: #831625;
    color: #fff;
    padding: 6px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: bold;
}

.button-ovs:hover {
    text-decoration: none;
    background-color: #dc0000;
    color: #fff;
}

.button-ovs:focus {
    background-color: #dc0000;
    color: #fff;
    text-decoration: none;
}

#search-button {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
    top: -1px;
}

.search-field {
    border-radius: 4px;
    border: 1px solid #6e6e6e;
    margin-top: 10px;
    max-width: 35em;
}

.search-bar {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #f4f4f4;
    padding: 20px;
}

.search-label {
    font-weight: normal;
}

.header-recent-files {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: -15px;
    margin-left: -15px;
}

.files-info {
    font-size: 17px;
    font-weight: bold;
}

.home-metadata, .search-metadata, .browse-metadata {
    padding: 10px 0;
    border-bottom: 1px solid #cbcbcb;
    margin-top: 15px;
    margin-bottom: 15px;
}

.link {
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
    color: #255c9f;
    text-decoration: underline;
}

.data-control {
    margin: 10px 0 10px -15px;
    overflow: auto;
}

.page-information {
    margin-left: 15px;
}

.display-options {
    margin-top: 10px;
}

#expand-area {
    margin-top: 5px;
}

#header-subjects {
    margin-top: 20px;
    margin-bottom:20px;
    padding: 0px 0px 0px 15px;
}

#browse-info {
    font-size: 17px;
    font-weight: bold;
}

#subjects-list {
    border-right: 4px solid #a9a9a9;
    margin: 20px 10px 20px 20px;
}

#subject-selection-buttons {
    margin: 0px 0px 10px 0px;
}

#home-icon, #search-icon, #browse-icon, #about-icon, #contact-icon {
    background-position: 15px 15px;
    background-size: 25px;
}

#pager-adjusted {
    margin-top: 0;
    margin-bottom: 5px;
}

.paging-disabled {
    pointer-events: none;
}

.blok {
    margin-bottom: 20px;
}

p.bestandsnaam {
    font-size: 21px;
    color: #000;
    line-height: 25px;
} */
